import React, { Component } from "react";
import pdf from '../documents/Policies.pdf'

class Policies extends Component{
    render(){
        let width = typeof window === 'undefined' ? "0px" : window.innerWidth
        let height = typeof window === 'undefined' ? "0px" : window.innerHeight
        return <iframe src={pdf} width={width} height={height} style={{margin:"0px"}}/>
    }
}
export default Policies